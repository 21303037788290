<template>
  <div class="wrapper forgot-password text-center">
    <div class="title-wrap relative">
      <h3 class="page-titles">
        Change password
      </h3>
      <p>Enter the new password you want to reset to.</p>
    </div>

    <div class="w-1/2 mt-3 flex flex-col justify-center justify-items-center mr-auto ml-auto">
      <div
        class="p-8 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
        v-if="showErrorMsg"
      >
        Error changing password
      </div>
      <div class="form-control">
        <label class="label">
          <span class="label-text text-lg text-primary-dark" />
        </label>
        <input
          v-model="password"
          type="password"
          :class="{'input-error': errorMsg}"
          placeholder="Password"
          class="py-6 px-4 mb-4 leading-tight border text-base focus:outline-none"
        >

        <label class="label">
          <span class="text-lg label-text text-primary-dark" />
        </label>
        <input
          v-model="password2"
          type="password"
          :class="{'input-error': errorMsg}"
          placeholder="Confirm password"
          class="py-6 px-4 mb-4 leading-tight border text-base focus:outline-none"
        >
      </div>
      <div class="w-full flex justify-center">
        <button
          @click="changePassword"
          class="btn-primary mt-8"
        >
          Confirm
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      password: '',
      password2: '',
      errorMsg: false,
      showErrorMsg: false
    }
  },
  methods: {
    async changePassword () {
      if (this.password !== this.password2) {
        this.errorMsg = true
        return
      }
      try {
        await this.$api.changePassword(this.password)
        this.$router.push({ name: 'Home' })
      } catch (error) {
        if (error.response.status === 404) {
          this.showErrorMsg = true
        }
      }
    }
  }
}
</script>

<style></style>
